import '../css/home.scss';
import Swiper, { Navigation, Autoplay, EffectFade } from 'swiper';
import {
  loadSlideGender, loadSlideproductType, loadSliderUnivers, loadSliderReviews, loadSlideSliderCollection,
} from './modules/oryx/front/homesliders.js';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';
import 'swiper/scss/effect-fade';

import './modules/ps_emailsubscription/ps_emailsubscription';

function yieldToMain() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}
async function saveSettings() {
  // Create an array of functions to run:
  const tasks = [
    loadSlideGender,
    loadSlideproductType,
    loadSliderUnivers,
    loadSliderReviews,
    loadSlideSliderCollection,
  ];

  // Loop over the tasks:
  while (tasks.length > 0) {
    // Shift the first task off the tasks array:
    const task = tasks.shift();
    console.log('task : ', task);
    // Run the task:
    task();

    // Yield to the main thread:
    await yieldToMain();
  }
}

// saveSettings();

$(document).ready(() => {
  const swiper = new Swiper('#pshomeslider', {
    modules: [Navigation, Autoplay, EffectFade],
    rewind: true,
    autoplay: {
      delay: 3000,
    },
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init() {
        if ($(window).height() > $(window).width() && $(window).width() < 1024) {
          // $('#pshomeslider').find('.swiper-slide').css({
          //   "height": "312px"
          // });
        } else {
          const width = $('#pshomeslider').find('.swiper-slide-active').width();
          const ratio = 2400 / width;
          const height = 800 / ratio;
          $('#pshomeslider').find('.swiper-slide img').css({
            height: `${height}px`,
          });
        }
      },
    },
  });

  loadSlideGender();

  loadSlideproductType();

  loadSliderUnivers();

  loadSliderReviews();

  loadSlideSliderCollection();
});
